<template>
  <v-container v-if="!_.isEmpty(projectBagEditable)">
    <div v-if="!showProjectListForTransfer && !showTransfersSummary && !showTransferCalculator">
      <!-- Banner header -->
      <Banner :title="'Bolsa'">
        <template v-slot:actions>
          <v-btn
            v-if="permissionsBannerActions.changeToInvestable.includes(user.role) && projectBagEditable.status === 'CREATED'"
            @click="dialogChangeToInvestable = true"
            small
          >
            Pasar a invertible
          </v-btn>
          <v-btn
            v-if="permissionsBannerActions.send.includes(user.role) && projectBagEditable.status === 'FUNDED'"
            @click="handleBtnSend()"
            small
          >
            Enviar bolsa
          </v-btn>

          <v-divider v-if="projectBagEditable.status === 'CREATED'" vertical inset class="mx-4" />

          <v-btn
            v-if="permissionsBannerActions.visibility.includes(user.role) && projectBagEditable.status === 'INVESTABLE'"
            small
            @click="toggleVisibility()"
          >
            <v-icon v-if="!projectBagEditable.visibility" small>visibility</v-icon>
            <v-icon v-else small>visibility_off</v-icon>
          </v-btn>
          <v-btn
            v-if="permissionsBannerActions.delete.includes(user.role) && projectBagEditable.status === 'CREATED'"
            color="red"
            @click="dialogDeleteProjectBag = true"
            small
          >
            <v-icon color="white" small>delete</v-icon>
          </v-btn>
        </template>
      </Banner>

      <!-- Form project bag data -->
      <v-card class="my-8 pa-5">
        <v-card-text class="pa-5">
          <v-form ref="formEditprojectBag" v-model="formEditprojectBagValid">
            <v-text-field :value="projectBagEditable.status" label="Estado" readonly />
            <v-text-field v-model="projectBagEditable.name" label="Nombre" readonly />
            <v-textarea v-model="projectBagEditable.description" label="Descripción" rows="1" auto-grow readonly />
            <v-text-field :value="currency(projectBagEditable.amount, { fromCents: true })" label="Cantidad solicitada" readonly />
            <v-text-field :value="currency(projectBagEditable.amount_invested, { fromCents: true })" label="Cantidad invertida" readonly />
            <v-text-field :value="`${projectBagEditable.percentage_invested} %`" label="Porcentaje invertido" readonly />
            <v-text-field :value="currency(projectBagEditable.amount_repaid, { fromCents: true })" label="Cantidad devuelta" readonly />
            <v-text-field :value="partners.find((e) => e.id === projectBagEditable?.partner.id)?.name" label="Partner" readonly />
            <DatePicker
              :parent_model="projectBagEditable.finish_date"
              label="Fecha de finalización"
              :min="dateNowFormated()"
              :readonly="!editable"
              :required="true"
              @change="projectBagEditable.finish_date = $event"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-icon v-if="!editable && permissionsCardsActions.profileData.edit.includes(user.role)" @click="editable = true">edit</v-icon>
          <v-btn v-if="editable" text @click="(editable = false), getProjectBag({ filter: { id: $route.params.id } })">Cancelar</v-btn>
          <v-btn v-if="editable" text @click="edit">Guardar</v-btn>
        </v-card-actions>
      </v-card>

      <!-- Bag projects table and dialogs -->
      <Projects :projectBagEditable="projectBagEditable" />

      <!-- Investments table and dialogs -->
      <Investments :projectBagEditable="projectBagEditable" />

      <!-- Dialog change credit transfer to sent -->
      <DialogBox :model="dialogChangeToInvestable" :color="'warning'">
        <template slot="toolbar">
          <span>¿Estás seguro de pasar la bolsa a invertible?</span>
        </template>
        <template slot="actions">
          <v-btn text @click="dialogChangeToInvestable = false">Cerrar</v-btn>
          <v-btn text @click="handleBtnToInvestable()"> pasar a invertible </v-btn>
        </template>
      </DialogBox>

      <!-- Dialog delete investment -->
      <DialogBox :model="dialogDeleteProjectBag" :color="'error'" isdark>
        <template slot="toolbar">
          <span>¿Estás seguro de eliminar la bolsa?</span>
        </template>
        <template slot="actions">
          <v-btn text @click="dialogDeleteProjectBag = false">Cerrar</v-btn>
          <v-btn text @click="deleteProjectBag()">eliminar</v-btn>
        </template>
      </DialogBox>
    </div>

    <div v-else>
      <Banner :title="'Crear envío'">
        <template v-slot:actions>
          <v-btn @click="(showProjectListForTransfer = false), (showTransfersSummary = false), (showTransferCalculator = false)" small>
            Volver a la bolsa
          </v-btn>
        </template>
      </Banner>

      <v-card min-height="75vh" class="mt-5">
        <v-card-text>
          <ProjectListForTransfer v-if="showProjectListForTransfer" :projectsTransfer="projectsTransfer" />
          <TransfersSummary v-if="showTransfersSummary" :selectedProjects="projectsTransfer" :selectedPartner="selectedPartner" />
          <TransferCalculator
            v-if="showTransferCalculator"
            ref="calculator"
            :selectedProjects="projectsTransfer"
            :selectedPartner="selectedPartner"
          />
        </v-card-text>
      </v-card>

      <div class="d-flex justify-space-between mt-3">
        <v-btn :disabled="!showTransferCalculator && !showTransfersSummary" @click="handleBtnBack()">Volver</v-btn>
        <v-btn v-if="!showTransferCalculator && !showTransfersSummary" @click="handleBtnShowTransfersSummary()">Ver resumen</v-btn>
        <v-btn v-if="showTransfersSummary" @click="(showTransfersSummary = false), (showTransferCalculator = true)">calculadora</v-btn>
        <v-btn v-if="showTransferCalculator" @click="dialogCreateCreditTransfer = true">Crear transferencia</v-btn>
      </div>

      <!-- Dialog create credit transfer -->
      <DialogBox :model="dialogCreateCreditTransfer">
        <template slot="toolbar">
          <span>¿Estás seguro de crear el envío?</span>
        </template>
        <template slot="actions">
          <v-btn text @click="dialogCreateCreditTransfer = false">Cerrar</v-btn>
          <v-btn text @click="(dialogCreateCreditTransfer = false), $refs.calculator.createMoneyTransfers(false, true, projectBag.id)">
            crear
          </v-btn>
        </template>
      </DialogBox>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import CommonMixin from "@/mixins/CommonMixin";
import _ from "lodash";
import Banner from "@/components/elements/Banner";
import DatePicker from "@/components/elements/DatePicker";
import Investments from "@/components/elements/project-bags/Investments";
import Projects from "@/components/elements/project-bags/Projects";
import DialogBox from "@/components/elements/DialogBox";
import ProjectListForTransfer from "@/components/elements/project-bags/ProjectListForTransfer.vue";
import TransfersSummary from "@/components/elements/credit-transfers/TransfersSummary.vue";
import TransferCalculator from "@/components/elements/credit-transfers/transfer-calculator/TransferCalculator.vue";
import * as currency from "currency.js";

export default {
  name: "projectBag",
  components: {
    Banner,
    DatePicker,
    Investments,
    Projects,
    ProjectListForTransfer,
    TransfersSummary,
    TransferCalculator,
    DialogBox
  },
  mixins: [FormRulesMixin, FormatDateMixin, CommonMixin],
  data() {
    return {
      showProjectListForTransfer: false,
      showTransfersSummary: false,
      showTransferCalculator: false,
      projectsTransfer: [],
      selectedPartner: {},

      // Banner actions permissions
      permissionsBannerActions: {
        send: ["SUPERADMIN", "ADMIN"],
        changeToInvestable: ["SUPERADMIN", "ADMIN"],
        visibility: ["SUPERADMIN", "ADMIN"],
        delete: ["SUPERADMIN", "ADMIN"]
      },

      // Cards actions permissions
      permissionsCardsActions: {
        profileData: {
          edit: ["SUPERADMIN"]
        }
      },

      // Form edit projectBag
      editable: false,
      formEditprojectBagValid: false,
      projectBagEditable: {},

      // Dialogs
      dialogDeleteProjectBag: false,
      dialogChangeToInvestable: false,
      dialogCreateCreditTransfer: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      partners: (state) => state.partners.partners,
      projectBag: (state) => state.projectBags.projectBag
    }),
    _() {
      return _;
    },
    currency() {
      return currency;
    }
  },
  watch: {
    projectBag() {
      this.projectBagEditable = this.projectBag;
    },
    editable() {
      this.$store.commit("SET_EDITING", this.editable);
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await this.getProjectBag({ filter: { id: this.$route.params.id } });
    // Push back if id does not exist
    if (this._.isEmpty(this.projectBagEditable) || this.projectBagEditable.id !== this.$route.params.id) {
      this.$router.push("/bolsas");
      this.$store.commit("SET_LOADING", false);
      return;
    }
    await this.$store.dispatch("partners/getPartners", { size: 10, sort: { created_at: "DESC" } });
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    ...mapActions({
      getProjectBag: "projectBags/getProjectBag"
    }),

    // Edit projectBag
    async edit() {
      if (this.$refs.formEditprojectBag.validate()) {
        let projectBagEditableFormattedData = {
          id: this.projectBagEditable.id,
          finish_date: this.formatDateToISO8601(this.projectBagEditable.finish_date)
        };

        await this.$store.dispatch("projectBags/editProjectBag", projectBagEditableFormattedData);

        this.editable = false;
      }
    },

    async handleBtnToInvestable() {
      this.dialogChangeToInvestable = false;
      await this.$store.dispatch("projectBags/changeToInvestable", this.projectBagEditable.id);
      await this.getProjectBag({ filter: { id: this.$route.params.id } });
    },

    async handleBtnSend() {
      this.projectsTransfer = await this.$store.dispatch("projectBags/calculateCreditsTransfersInBag", this.projectBagEditable.id);
      await this.$store.dispatch("currencies/getCurrencies");
      await this.getProjectBag({ filter: { id: this.$route.params.id } });
      this.showProjectListForTransfer = true;
    },

    async handleBtnShowTransfersSummary() {
      this.selectedPartner = await this.$store.dispatch("partners/getPartner", { filter: { id: this.projectBagEditable.partner_id } });
      this.showProjectListForTransfer = false;
      this.showTransfersSummary = true;
    },

    // Button to back to project list for transfer or to list summary
    handleBtnBack() {
      if (this.showTransfersSummary) {
        this.showTransfersSummary = false;
        this.showProjectListForTransfer = true;
      } else if (this.showTransferCalculator) {
        this.showTransferCalculator = false;
        this.showTransfersSummary = true;
      }
    },

    async toggleVisibility() {
      await this.$store.dispatch("projectBags/editProjectBag", {
        id: this.projectBagEditable.id,
        visibility: !this.projectBagEditable.visibility
      });

      await this.getProjectBag({ filter: { id: this.$route.params.id } });
    },

    async deleteProjectBag() {
      await this.$store.dispatch("projectBags/deleteProjectBag", this.projectBagEditable.id);
      this.dialogDeleteProjectBag = false;
      this.$router.replace("/bolsas");
    }
  }
};
</script>
